'use strict';

var addToCart = require('./base').addToCart;
var TileATC = {};

/**
 * ATC - Tile
 */
function updateAddToCart(element) {
    $(element).on('updateAddToCartFormData', function (e, form) {
        //form.pid || $(this).data('pid')
        form.pid = $(this).data('pid');
        form.quantity = $(this).data('qty');
        // remove listener
        $(this).off('updateAddToCartFormData');
        $.spinner().start();
        return form;
    });
}

TileATC.beforeAddToCart = function () {
    $('body').on('product:beforeAddToCart', function (e, tile) {
        if ($(tile).hasClass('quick-atc')) {
            updateAddToCart(tile);
        }
    });
};

/**
 * Causes double click event on PLP because Quickview/QuickAdd also initializes the ATC click
 * Only reason this needs to exists is if the Quickview and/or QuickAdd is removed from the Tiles
 */
// TileATC.addToCart = addToCart;

module.exports = TileATC;

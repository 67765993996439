'use strict';

$('.content-text-slider').each(function () {
    if (!$(this).hasClass('slick-initialized')) {
        $('.content-text-slider').slick({
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 0
        });
    }
});

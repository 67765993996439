'use strict';

module.exports = function () {
    var $collection = $('.fade-in-up');

    $collection.each(function () {
        new Waypoint({
            element: this,
            handler: function(direction) {
                $(this.element).addClass('visible');
            },
            offset: '75%'
        })
    });

    $(document).on('lazyloaded', function () {
        Waypoint.refreshAll();
    });
};

'use strict';

module.exports = {
    mainProductCarousel: {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        arrows: false,
        rows: 0,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    },
    thumbProductCarousel: {
        dots: false,
        arrows: true, // arrows disabled on vertical for now -- needs refining
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        rows: 0,
        focusOnSelect: true,
        vertical: true,
        verticalSwiping: true,
    },
    cartRecommendation: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            }
        ]
    },
    quickAdd: {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        arrows: false,
        rows: 0,
        slidesToScroll: 1
    }
};
'use strict';

module.exports = function () {

    $(document).ready(function () {
        window.hvFixFlag = true;
        // detach the hosted video modals and move them to #site-main so their positioning is not constrained by containing Page Designer layout elements.
        $('.hv-modal').each(function() {
            $(this).detach().appendTo('#site-main');
        });
        $('body').on('click', '.hv-poster', function (e) {
            $('.hv-video-figure video').each(function(ind, vid) {
                vid.pause();
            });
            var componentId = $(this).data('component-id');
            if ($(this).closest('.pd-hosted-video-component').hasClass('play-in-modal') && window.innerWidth > 768) {
                var $modal = $('#hv-modal-' + componentId);
                console.log('$modal:');
                console.dir($modal);
                var modalVideo = $modal.find('video')[0];
                $modal.on('show.bs.modal', function (e) {
                    modalVideo.play();
                })
                .on('hide.bs.modal', function() {
                    modalVideo.pause();
                });
                // open modal player
                console.log('try to open modal');
                $modal.modal('show');
                
            } else {
                var $poster = $(this);
                var $interface = $poster.siblings('figcaption'); // text and button
                var video = $(this).siblings('.hv-video-figure').find('video')[0];
                if (video && (!$poster.hasClass('static-mobile') || window.innerWidth > 769)) { // don't do this stuff on mobile when a static image is to be shown on mobile
                    // hide poster and text and button
                    $poster.addClass('js-hidden');
                    $interface.addClass('js-hidden');
                    // start the video
                    video.play();
                    // when video is stopped, restore poster and text and button
                    video.addEventListener('pause', function showStuff(event) {
                        $poster.removeClass('js-hidden');
                        $interface.removeClass('js-hidden');
                    });
                }
            }
        });
    });
    
};

'use strict';

var base = require('base/login/login');

base.checkHrefForResetPasswordModal = function () {
    if (document.location.href.indexOf('password-reset-form') != -1) {
        $("#requestPasswordResetModal").modal('show');
      }
}

base.switchToLoginForm = function () {
    $('.reset-password-form').on('click', '#submitEmailButton[data-dismiss="modal"]', function () {
        if ($('.nav-link[href="#login"]:not(.active)').length) {
            $('.nav-link[href="#login"]').trigger('click');
        // })
        } else if (!$('.nav-link[href="#login"]').length) {
        // $('.reset-password-form').on('click', '#submitEmailButton[data-dismiss="modal"]', function () {
            var location = $(this).data('login-href');
            window.location.href = location;
        }
    })
}

module.exports = base;
'use strict';

module.exports = function () {
    // remove body preload class that prevents animations on page load
    setTimeout(function () {
        $('body').removeClass('preload');
    }, 500);

    if ($('.js-date-input').length) {
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            $('.js-date-input').each(function (i, e) {
                $(e).attr('type', 'date')
                    .removeAttr('onfocus')
                    .removeAttr('onblur')
                    .siblings('.form-control-label').addClass('d-block');
            })

        }
    }

    $('body').on('change', '.js-date-input', function (e) {
        var maxTime = new Date(e.target.max).getTime();
        var valueTime = new Date(e.target.value).getTime()
        var $this = $(this);
        var errorMsg = $this.data("exceed-error");
        
        if (maxTime < valueTime) {
            $this.addClass('is-invalid').siblings('.invalid-feedback').show().html(errorMsg);
        } else {
            $this.removeClass('is-invalid').siblings('.invalid-feedback').hide().html('');
        }
    });

    $('body').on('blur', 'input[type="email"], .js-trim-value', function (e) {
        var value = e.target.value;
        $(this).val($.trim(value));
    });
};

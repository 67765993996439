'use strict';

// console.log('executing app_belkin footer.js...');
var viewPortHelper = require('../helpers/viewport');

/**
 * Method to open locale modal
 * @param {string} $target - target modal
 * @param {string} action - user action
 */
function togglePreferenceModal($target, action) {
    $($target).modal(action);
}

/**
 * removing message
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} form - email sign-up form
 * @param {*} errorContainer - container for error message
 */
function removeMessage(data, form) {
    if (data.success) {
        form.find('input:not([type="hidden"])').val('');
        setTimeout(function () {
            $('.email-signup-message').remove();
            form.find('.js-subscribe-email').removeAttr('disabled');
        }, 3000);
    } else {
        form.find('.js-subscribe-email').removeAttr('disabled');
    }
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} form - email sign-up form
 */
function displayMessage(data, form) {
    $.spinner().stop();
    var status;
    var title;
    var $errorContainer = $(form).find('.js-invalid-feedback');

    if (data.success) {
        status = 'alert-success';
        $errorContainer.html('').removeClass('d-block');

        var $preferenceModal = $('.sfmc-preferences-modal');
        togglePreferenceModal($preferenceModal, 'show');
    } else {
        $errorContainer.first().addClass('d-block').html(data.msg);

        removeMessage(data, form);
        return;
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
            '<div class="email-signup-message"></div>'
        );
    }
    $('.email-signup-message')
        .append('<div class="add-to-wishlist-alert text-center ' + status + '">'
            + '<span class="message">'
                    + data.msg
            + '</span>'
        + '</div>');

    removeMessage(data, form);
}

/**
 * Animation for locale blocks
 * @param {Object} $obj - animated object
 * @param {number} ind - start index
 */
function localeSelectorAnimation($obj, ind) {
    // console.log('running localeSelectorAnimation.');
    if (ind && ind > ($obj.length - 1)) {
        return;
    }

    var index = 0;

    if ($obj.length > 1 && ind) {
        index = ind;
    }

    $($obj[index]).animate(
        {
            height: 'auto'
        },
        {
            step: function () {
                $(this).addClass('m-show');
            },
            done: function () {
                index++;

                if (viewPortHelper.isDesktop()) {
                    var $currentLocation = $($obj).find('.js-current-location');
                    if ($currentLocation.length) {
                        $currentLocation.trigger('click');
                    }
                }

                localeSelectorAnimation($obj, index);
            }
        }
    );
}

/**
 * Decline animation for location blocks
 * @param {Object} $obj - target object
 */
function stopLocaleAnimation($obj) {
    $obj.stop().removeClass('m-show');
}

/**
 * Slide down/up for region items
 * @param {Object} $obj - target object
 * @param {string} animation - kind of animation
 */
function slidingRegionItems($obj, animation) {
    // console.log('running slidingRegonItems: animation' + JSON.stringify(animation, null, 2));
    if (animation === 'collapse') {
        $obj.parent().slideUp();
    } else if (animation === 'extend') {
        $obj.parent().slideDown();
    }
}

module.exports = function () {
    $('#newsletter-signup').on('submit', function (e) {
        e.preventDefault();
        var $form = $(this);
        var url = $(this).attr('action');

        $.spinner().start();
        $('#email-for-reference').val($('#dwfrm_mcsubscribe_email').val());
        $form.find('.js-subscribe-email').attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                displayMessage(data, $form);
            },
            error: function (err) {
                displayMessage(err, $form);
            }
        });
    });

    $('#preferences-options').on('submit', function (e) {
        e.preventDefault();
        var url = $(this).attr('action');

        $('.sfmc-error-message').empty();
        var $newProduct = $('#new_product').is(':checked');
        var $exclusiveSales = $('#exclusive_sales').is(':checked');
        var $productRecs = $('#product_recs').is(':checked');
        var $businessSolutions = $('#business_solutions').is(':checked');
        var $softwareServices = $('#software_services').is(':checked');
        if (!$newProduct && !$exclusiveSales && !$productRecs && !$businessSolutions && !$softwareServices) {
            $('.sfmc-error-message').html('Please make a selection');
            return;
        }

        var form = {
            email: $('#email-for-reference').val(),
            newProduct: $newProduct,
            exclusiveSales: $exclusiveSales,
            productRecs: $productRecs,
            businessSolutions: $businessSolutions,
            softwareServices: $softwareServices
        };
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form,
            success: function () {
                var $preferenceModal = $('.sfmc-preferences-modal');
                togglePreferenceModal($preferenceModal, 'hide');

                var $successModal = $('.modal-sfmc-preferences-success');
                togglePreferenceModal($successModal, 'show');

                setTimeout(function () {
                    togglePreferenceModal($successModal, 'hide');
                }, 2000);

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('#blk-preferences-options').on('submit', function (e) {
        e.preventDefault();
        var url = $(this).attr('action');
        $('.sfmc-error-message').empty();

        var $subscribe_all = $('#subscribe_all').is(':checked');
        var $Apple_Device = $('#Apple_Device').is(':checked');
        var $Android_Device = $('#Android_Device').is(':checked');
        var $Networking = $('#Networking').is(':checked');
        var $Business = $('#Business').is(':checked');
        var $Offers = $('#Offers').is(':checked');
        var $New_Products = $('#New_Products').is(':checked');
        var $Speakers_Headphones = $('#Speakers_Headphones').is(':checked');
        var $Blog_Alerts = $('#Blog_Alerts').is(':checked');

        if (!$subscribe_all && !$Apple_Device && !$Android_Device && !$Networking && !$Offers && !$Business && !$New_Products && !$Speakers_Headphones && !$Blog_Alerts) {
            $('.sfmc-error-message').html('Error please try again.');
            return;
        }

        if ($subscribe_all) {
            $Apple_Device = true;
            $Android_Device = true;
            $Networking = true;
            $Offers = true;
            $New_Products = true;
            $Speakers_Headphones = true;
            $Blog_Alerts = true;
        }
        var form = {
            email: $('#email-for-reference').val(),
            subscribe_all: $subscribe_all,
            Apple_Device: $Apple_Device,
            Android_Device: $Android_Device,
            Networking: $Networking,
            Offers: $Offers,
            Business: $Business,
            New_Products: $New_Products,
            Speakers_Headphones: $Speakers_Headphones,
            Blog_Alerts: $Blog_Alerts
        };
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form,
            success: function () {
                var $preferenceModal = $('.sfmc-preferences-modal');
                togglePreferenceModal($preferenceModal, 'hide');

                var $successModal = $('.modal-sfmc-preferences-success');
                togglePreferenceModal($successModal, 'show');

                setTimeout(function () {
                    togglePreferenceModal($successModal, 'hide');
                }, 2000);

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('.js-locale-selector-open').on('click', function () {
        // console.log('.js-locale-selector-open clicked.');
        var $animatedBlock = $(this).closest('.js-locale-selector-block').find('.js-locale-lazy');

        $('body').addClass('modal-open');
        localeSelectorAnimation($animatedBlock);
        if (viewPortHelper.isDesktop()) {
            $('.js-current-location').addClass('m-active');
        }
    });

    $('.js-locale-close').on('click', function () {
        // console.log('.js-locale-close clicked.');
        var $objs = $('.js-locale-lazy, .js-location-block');
        stopLocaleAnimation($objs);
        $('.js-location-link, .js-region-link').removeClass('m-active');
        slidingRegionItems($('.js-region-link'), 'extend');
        $('body').removeClass('modal-open');
    });

    $('.js-location-close').on('click', function () {
        // console.log('.js-location-close clicked.');
        stopLocaleAnimation($('.js-location-block'));
    });

    $('.js-region-link').on('click', function (e) {
        // console.log('.js-region-link clicked.');
        var $this = $(this);
        var activeRegionId = $this.data('link');
        // console.log('activeRegionId=' + activeRegionId);
        // console.log('uploaded through prophet');

        // console.log('$(this):');
        // console.dir($(this));
        if ($(this).hasClass('m-active')) {
            // console.log('this is .m-active');
            $(this).removeClass('m-active');
            slidingRegionItems($('.js-region-link'), 'extend');
            stopLocaleAnimation($('.js-location-block'));
            return;
        }
        // console.log('this is NOT .m-active');
        $('.js-region-link').removeClass('m-active');
        $(this).addClass('m-active');

        if (!viewPortHelper.isDesktop()) {
            var $targetRegionItem = $this.closest('.js-region-block').find('.js-region-link:not(.m-active)');
            slidingRegionItems($targetRegionItem, 'collapse');
        }

        var $locationBlock = $this.closest('.js-locale-selector-block').find('.js-location-block');
        var $locationList = $this.closest('.js-locale-selector-block').find('.js-location-list');

        $locationList.removeClass('m-show');
        $('[data-region="' + activeRegionId + '"]').addClass('m-show');

        if ($locationList.children().length === 0 || !$locationBlock.is('.m-show')) {
            localeSelectorAnimation($locationBlock);
        }
    });

    $('.change-reg').on('click', function () {
        // console.log('.change-reg clicked.');
        $('.navbar-toggler').trigger('click');
        $('.js-locale-selector-open').trigger('click');
    });
};

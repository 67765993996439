/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-concat */
/* eslint-disable valid-jsdoc */
'use strict';

// console.log('executing app_belkin extend/cart.js');

const { data } = require("jquery");

var Extend = window.Extend || undefined;

/**
 * Tracking adding offers to cart via cart/minicart
 */
function trackOfferAddedToCart(data) {
    if (typeof Extend === 'undefined') {
        return;
    }
    Extend.trackOfferAddedToCart({
        productId: data.pid,
        productQuantity: data.quantity,
        warrantyQuantity: data.quantity,
        planId: data.extendPlanId,
        offerType: {
            area: 'cart_page',
            component: 'modal'
        }
    });
}

/**
 * Renders an Extend upsell button in cart page
 * @param {string} uuid - line item uuid
 * @param {string} pid - corresponding product id
 * @param {string} qty- corresponding quantity
 * @returns
 */
function addExtendUpsellBtnCart(uuid, pid, qty) {
    var hasExtendUpsell = $('.item-' + uuid).parents('.cart-product__details-content').find('#extend-offer-' + uuid).length > 0;
    var isRenderButton = $('#footercontent').find('input[name=noRenderExtendButton]').length;
    if (!hasExtendUpsell && !isRenderButton && typeof Extend !== 'undefined') {
        $('<div class="extend-upsell-style" id="extend-offer-' + uuid + '" data-pid=' + pid + '></div>')
            .insertAfter('.item-' + uuid)
            .ready(function () {
                /** initialize offer */
                Extend.buttons.renderSimpleOffer('#extend-offer-' + uuid, {
                    referenceId: pid,
                    onAddToCart:
                        function (plan) {
                            if (plan) {
                                var form = {};
                                form.extendPlanId = plan.plan.planId;
                                form.extendPrice = plan.plan.price;
                                form.extendTerm = plan.plan.term;
                                form.pid = pid;
                                form.pliUUID = uuid;
                                form.quantity = qty;
                                trackOfferAddedToCart(form);

                                $.spinner().start();

                                $.ajax({
                                    url: window.EXT_CART_ADDTOCART,
                                    method: 'POST',
                                    data: form,
                                    success: function () {
                                        // location.reload();
                                        $('body').trigger('product:afterAddToCart', data);
                                    },
                                    error: function () {
                                        $.spinner().stop();
                                    }
                                });
                            }
                        }
                });
            });
    }
}

/**
 * Renders an Extend upsell button under Mini Cart popup
 * @param {string} uuid - line item uuid
 * @param {string} btnLabel - upsell button label
 * @param {string} pid - corresponding product id
 */
function addExtendUpsellBtnInMiniCart(uuid, pid, qty) {
    // console.log('running addExtendUpsellBtnInMiniCart...');
    var hasExtendUpsell = $('.minicart').find('#minicart-extend-offer-' + uuid).length > 0;
    // console.log('checking for .minicart #minicart-extend-offer-' + uuid + 'length > 0...');
    // console.log('hasExtendUpsell = ' + hasExtendUpsell);
    var isRenderButton = $('#footercontent').find('input[name=noRenderButton]').length;
    var isShippingProtecting = pid === 'EXTEND-SHIPPING-PROTECTION' ? true : false;
    if (!hasExtendUpsell && !isRenderButton && !isShippingProtecting && typeof Extend !== 'undefined') {

        $('<div class="extend-upsell-style" id="minicart-extend-offer-' + uuid + '" data-pid=' + pid + '></div>')
        .insertAfter('.minicart .product-summary .uuid-' + uuid)
        .ready(function () {
            /** initialize offer */
            Extend.buttons.renderSimpleOffer('#minicart-extend-offer-' + uuid, {
                referenceId: pid,
                onAddToCart:
                    function (plan) {
                        if (plan) {
                            var form = {};
                            form.extendPlanId = plan.plan.planId;
                            form.extendPrice = plan.plan.price;
                            form.extendTerm = plan.plan.term;
                            form.pid = pid;
                            form.pliUUID = uuid;
                            form.quantity = qty;
                            trackOfferAddedToCart(form);

                            $.spinner().start();

                            $.ajax({
                                url: window.EXT_CART_ADDTOCART,
                                method: 'POST',
                                data: form,
                                success: function () {
                                    /** extend analytics integration */
                                    // location.reload();
                                    $('body').trigger('product:afterAddToCart', data);
                                },
                                error: function () {
                                    $.spinner().stop();
                                }
                            });
                        }
                    }
            });
        });
    }
}

/**
 * Produces the request for render upsell buttons
 */
function makeRequestForRender(uuid, renderUpsellBtnCallback) {
    // console.log('running makeRequestForRender.');
    // console.log('window.EXT_CART_WARRANTYCHECK = ' + window.EXT_CART_WARRANTYCHECK);
    $.ajax({
        url: window.EXT_CART_WARRANTYCHECK + '?uuid=' + uuid,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            if (data.isEligible) {
                renderUpsellBtnCallback(uuid, data.pid, data.qty);
            }
        },
        error: function () { }
    });
}

/**
 * Extend config is initialized
 */
function initExtend() {
    $(document).ready(function () {
        if (typeof Extend === 'undefined') {
            return;
        }
        var EXT_STORE_ID = window.EXT_STORE_ID || undefined;
        var EXT_ENVIRONMENT = window.EXT_ENVIRONMENT || undefined;
        Extend.config({ storeId: EXT_STORE_ID, environment: EXT_ENVIRONMENT });
    });
}

/**
 * Render the upsell buttons
 */
function renderUpsellBtns() {
    if (!window.EXT_CART_UPSELL_SWITCH || typeof Extend === 'undefined') {
        return;
    }

    var isRMACheckout = $('#isRMACheckout');
    if (isRMACheckout && isRMACheckout.val() === 'true') {
        return;
    }

    $('.cart-product').each(function (index, product) {
        var classes = $(product).attr('class').match(/uuid-(\w+)/);
        // console.log('classes=' + JSON.stringify(classes, null, 2));
        var uuid;

        if (classes) {
            uuid = classes[1];
        }

        makeRequestForRender(uuid, addExtendUpsellBtnCart);
    });
}

/**
 * Render the upsell buttons on mini cart
 */
function renderUpsellBtnsMiniCart() {
    if (!window.EXT_CART_UPSELL_SWITCH) {
        return;
    }
    $('.product-line-item').each(function (index, product) {
        var uuid = $(product).data('product-line-item');
        makeRequestForRender(uuid, addExtendUpsellBtnInMiniCart);
    });
}

/**
 * Rerender the button after the warranty is deleted from cart
 */
function updateUpsellBtns() {
    $('body').on('click', '.cart-delete-confirmation-btn', function () {
        $('body').on('cart:update', function () {
            renderUpsellBtns();
            renderUpsellBtnsMiniCart();
        });
    });

    // Render when Mini Cart loaded
    $('body').on('extend:minicart:loaded', function () {
        // console.log('body event extend:minicart:loaded triggered!');
        renderUpsellBtnsMiniCart();
    });
}

module.exports = {
    initExtend: initExtend,
    renderUpsellBtns: renderUpsellBtns,
    updateUpsellBtns: updateUpsellBtns
};

'use strict';

// console.log('%crunning miniCart.js in app_belkin', 'color: green');
var cart = require('../cart/cart');
var tooltip = require('./toolTip');
var viewPortHelper = require('../helpers/viewport');

var updateMiniCart = true;
var miniCartCloseDelay = 5000;
// var currShippingPromotions;

/**
 * minicart slider init
 */
function miniCartSlider() {
    setTimeout(function () {
        $('.mini-cart-slider').each(function () {
            if (!$(this).hasClass('slick-initialized')) {
                $('.mini-cart-slider').slick({
                    dots: false,
                    arrows: true,
                    infinite: true,
                    autoplay: false,
                    slidesToShow: 3,
                    slidesToScroll: 1
                });
                $('.minicart-body').css({ overflow: 'hidden' });
                $('.minicart-container .product-summary').css({ 'max-height': 'none' });
            }
        });
    }, 900);
}
/**
 * minicart determine discounts that the subtotal is near to
 * @param {string} data - data returned from the server's ajax call
 */
function determineNearDiscounts(data) {
    var cartShippingPromos = data.cart.shippingPromotions[0];
    var keys = Object.keys(cartShippingPromos);
    var promoCount = keys.length;
    var shippingPromoMessage;

    for (var i = 0; i < promoCount; i += 1) {
        var currCartPromoMessage = cartShippingPromos[keys[i]];
        if (currCartPromoMessage.visible === true) {
            shippingPromoMessage = currCartPromoMessage.discountMsg;
        }
    }

    $('.approaching-discounts').empty();
    $('.approaching-discounts').append('<div class="single-approaching-discount text-center">'
                + shippingPromoMessage + '</div>');
}

/**
 * minicart body check
 */
function miniCartBodyCheck() {
    if ($('.mini-cart-slider').length) {
        if ($('.mini-cart-slider').hasClass('slick-initialized')) {
            $('.minicart-body').css({ overflow: 'hidden' });
            $('.minicart-container .product-summary').css({ 'max-height': 'none' });
        } else {
            $('.minicart-body').css({ overflow: 'visible' });
            $('.minicart-container .product-summary').css({ 'max-height': 'fit-content' });
            miniCartSlider();
        }
    } else {
        setTimeout(function () {
            miniCartSlider();
            if ($('.mini-cart-slider').length === 0) {
                $('.minicart-body').css({ overflow: 'hidden' });
                $('.minicart-container .product-summary').css({ 'max-height': 'none' });
            }
        }, 2000);
    }
}
/**
 * open minicart and show contents
 */
function showMiniCart() {
    var url = $('.minicart').data('action-url');

    if ($('.minicart .popover.show').length === 0) {
        if (!updateMiniCart) {
            $('.minicart .popover, .js-minicart-background').addClass('show');
            return;
        }

        $('.minicart .popover, .js-minicart-background').addClass('show');
        $('.minicart .popover').spinner().start();
        $.get(url, function (data) {
            // $('.minicart .popover').empty();
            $('.minicart .popover').html(data);
            updateMiniCart = false;
            $('.minicart-body').css({ overflow: 'visible' });
            $('.minicart-container .product-summary').css({ 'max-height': 'fit-content' });
            $.spinner().stop();
        });
    }
    // if (!$('.mini-cart-slider').hasClass('slick-initialized')) {
    //     console.log('showMiniCart()');
    //     $('.minicart-body').css({ overflow: 'visible' });
    //     $('.minicart-container .product-summary').css({ 'max-height': 'fit-content' });
    //     miniCartSlider();
    // }

    if ($('.sticky-pdp-header.m-active').length >= 1) {
        $('.sticky-pdp-header.m-active').removeClass('m-active');
    }

    tooltip.initForBlocks();
}

/**
 * Open minicart
 * @param {Object} miniCartDismiss - timer for closing minicart
 */
function openMiniCart(miniCartDismiss) {
    // don't show minicart on mobile breakpoints
    if (viewPortHelper.isDesktop()) {
        clearTimeout(miniCartDismiss);
        showMiniCart();
        $('body').addClass('modal-open').trigger('minicartOpen');
    }
    miniCartBodyCheck();
}

/**
 * close minicart
 */
function closeMiniCart() {
    var windowScroll = $(window).scrollTop();
    updateMiniCart = true;
    $('.minicart .popover, .js-minicart-background').removeClass('show');
    $('body').removeClass('modal-open').trigger('minicartClose');
    $('.minicart .popover .mini-cart-slider').slick('unslick');
    if ($('.sticky-pdp-header').length >= 1 && windowScroll > 1000) {
        $('.sticky-pdp-header').addClass('m-active');
    }
}

/**
 * copy of base/components/miniCart with accel miniCart updates
 */
function base() {
    cart();

    var miniCartDismiss;

    $('.minicart-popover').on('focusin', function () {
        openMiniCart(miniCartDismiss);
        if ($('.mini-cart-slider').length) {
            miniCartSlider();
        } else {
            setTimeout(miniCartBodyCheck(), 2700);
        }
    });

    $('.minicart-popover').on('mouseenter', function () {
        openMiniCart(miniCartDismiss);
        if ($('.mini-cart-slider').length) {
            miniCartSlider();
        } else {
            setTimeout(miniCartBodyCheck(), 2700);
        }
    });

    $('.js-minicart-open').on('click', function (e) {
        if (!$(this).is('.is-empty') && viewPortHelper.isDesktop() && !$(this).is('.is-rma-active')) {
            e.preventDefault();
            $('.minicart-body').css({ overflow: 'visible' });
            $('.minicart-container .product-summary').css({ 'max-height': 'fit-content' });
            openMiniCart(miniCartDismiss);
        }
        // if ($('.mini-cart-slider').length) {
        //     console.log('minicart open if');
        //     miniCartSlider();
        // } else {
        //     console.log('minicart open else');
        //     $('.minicart-body').css({ overflow: 'visible' });
        //     $('.minicart-container .product-summary').css({ 'max-height': 'fit-content' });
        // }
    });

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
            $('.minicart .minicart-quantity').removeClass('d-none');
        } else if (count && count.isRMA) {
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
            $('.minicart .minicart-quantity').removeClass('d-none');
        } else {
            $('.minicart .minicart-quantity').addClass('d-none');
        }
    });

    $('body').on('click', '.js-minicart-close', function (event) {
        closeMiniCart();
    });

    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
        var url = $('.minicart').data('action-url');
        $('.minicart .popover').spinner().start();
        setTimeout(function () {
            $.get(url, function (data) {
                //$('.minicart .popover').empty();
                $('.minicart .popover').html(data);
                updateMiniCart = true;
                $.spinner().stop();
            });
        }, 500);
    });

    $('body').on('product:afterAddToCart', function (e, data) {
        var addedToCart = !data.error;
        var url = $('.minicart').data('action-url');
        $('.minicart .popover').spinner().start();
        $.get(url, function (data) {
            // $('.minicart .popover').empty();
            $('.minicart .popover').html(data);
            updateMiniCart = false;
            $.spinner().stop();
            openMiniCart(miniCartDismiss);
        }).always(function () {
            $('.minicart-body').css({ overflow: 'visible' });
            $('.minicart-container .product-summary').css({ 'max-height': 'fit-content' });
            setTimeout(miniCartBodyCheck(), 3200);
            setTimeout(function () {
                miniCartSlider();
                if ($('.mini-cart-slider').length === 0) {
                    $('.minicart-body').css({ overflow: 'hidden' });
                    $('.minicart-container .product-summary').css({ 'max-height': 'none' });
                }
            }, 3200);
        });

        if (data.cart.shippingPromotions && data.cart.shippingPromotions.length) {
            // console.log(data.cart);
            determineNearDiscounts(data);
        }

        if (data && data.cart && data.cart.items && data.cart.items.length) {
            $('.js-minicart-open').removeClass('is-empty');
        } else {
            $('.js-minicart-open').addClass('is-empty');
        }

        if (addedToCart) {
            updateMiniCart = true;
            $('.minicart-popover').trigger('focusin');

            miniCartDismiss = setTimeout(function () {
                closeMiniCart();
            }, miniCartCloseDelay);
        }
    });

    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    var status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append('<div class="add-to-wishlist-alert text-center ' + status + '">'
            + '<span class="message">'
            + data.msg
            + '</span>'
            + '</div>');

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
    var $targetElement = $('a[data-pid="' + data.pid + '"]').closest('.product-info').find('.remove-product');
    var itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid')
    };
    $('body').trigger('afterRemoveFromCart', itemToMove);
    setTimeout(function () {
        $('.cart.cart-page #removeProductModal').modal();
    }, 2000);
}
/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    $('body').on('click', '.product-move .move', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        var pid = $(this).data('pid');
        var optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        var optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            success: function (data) {
                displayMessageAndRemoveFromCart(data);
            },
            error: function (err) {
                displayMessageAndRemoveFromCart(err);
            }
        });
    });
}

module.exports = {
    base: base,
    moveToWishlist: moveToWishlist
};

'use strict';

var formValidation = require('../components/formValidation');
var createErrorNotification = require('../components/errorNotification');


function loginAjaxCall(form) {
    var url = form.attr('action');
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            form.spinner().stop();
            if (!data.success) {
                formValidation(form, data);
                $('form.login').trigger('login:error', data);
            } else {
                $('form.login').trigger('login:success', data);
                location.href = data.redirectUrl;
            }
        },
        error: function (data) {
            if (data.responseJSON.redirectUrl) {
                window.location.href = data.responseJSON.redirectUrl;
            } else {
                $('form.login').trigger('login:error', data);
                form.spinner().stop();
            }
        }
    });    
}

function registrationAjaxCall(form) {
    var url = form.attr('action');
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            console.log('Success from registration........'+data.success);
            form.spinner().stop();
            if (!data.success) {
                $('form.registration').trigger('login:register:error', data);
                formValidation(form, data);
            } else {
                $('form.registration').trigger('login:register:success', data);
                location.href = data.redirectUrl;
            }
        },
        error: function (err) {
            console.log('error from registration....'+err);
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
            }

            form.spinner().stop();
        }
    });        
}

module.exports = {
    login: function () {
        $('form.login').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);

            var captcha_key = form.attr('ckey');
            if(captcha_key && captcha_key !== null && captcha_key !== undefined && captcha_key !== '' && captcha_key !== 'null') 
            {
                grecaptcha.ready(function() {
                    try {
                        // do request for recaptcha token
                        // response is promise with passed token
                        grecaptcha.execute(captcha_key, {action: 'submit'}).then( function(token) {
                            $('input[name="captchaToken"]').val(token);         // add token value to form
                            // console.log('Token at login>> ' + token);
                            loginAjaxCall(form);
                            return false;
                        });
                    } catch(err) {
                        formValidation(form, {error: ['Invalid site key or not loaded']});
                        form.spinner().stop();
                        // console.log('error from registration....'+err);
                    }
                });
            }
            else
            {
                loginAjaxCall(form);
                return false;
            }

        });
    },

    // Account registration form...
    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.registration').trigger('login:register', e);

            var captcha_key = form.attr('ckey');
            if(captcha_key && captcha_key !== null && captcha_key !== undefined && captcha_key !== '' && captcha_key !== 'null') 
            {
                grecaptcha.ready(function() {
                    try {
                        // do request for recaptcha token
                        // response is promise with passed token
                        grecaptcha.execute(captcha_key, {action: 'submit'}).then( function(token) {
                            $('input[name="captchaToken"]').val(token);         // add token value to form
                            // console.log('Token at registration>> ' + token);
                            registrationAjaxCall(form);
                            return false;
                        });
                    } catch(err) {
                        formValidation(form, {error: ['Invalid site key or not loaded']});
                        form.spinner().stop();
                        // console.log('error from registration....'+err);
                    }
                });
            }
            else
            {
                registrationAjaxCall(form);
                return false;
            }

        });
    },

    resetPassword: function () {
        $('.reset-password-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');
                        if (!data.mobile) {
                            $('#submitEmailButton').text(data.buttonText)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $('.send-email-btn').empty()
                                .html('<a href="'
                                    + data.returnUrl
                                    + '" class="btn btn-primary btn-block">'
                                    + data.buttonText + '</a>'
                                );
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    }
};

'use strict';

// console.log('%crunning main.js in app_belkin', 'color: green');
window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    $('body').addClass('js-enabled'); // enables animation & prevents images from being invisible
    processInclude(require('appCore/components/common'));
    processInclude(require('belkin/components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('belkin/components/header'));
    processInclude(require('belkin/components/footer'));
    processInclude(require('belkin/kibo'));
    processInclude(require('belkin/components/miniCart'));
    processInclude(require('belkin/components/formLabels'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('belkin/components/search'));
    processInclude(require('appCore/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('belkin/components/toolTip'));
    processInclude(require('appCore/accelerator/slider'));
    processInclude(require('belkin/product/tileAddToCart'));
    processInclude(require('belkin/product/quickView'));
    processInclude(require('core/components/instagram'));
    processInclude(require('belkin/components/localedetection'));
    processInclude(require('appCore/login/login'));
    processInclude(require('appCore/components/video'));
    processInclude(require('appCore/components/hostedVideo'));
    processInclude(require('appCore/components/animation'));
    processInclude(require('belkin/contentSlider'));
    processInclude(require('belkin/klarnaMessaging'));
    processInclude(require('belkin/extend/cart'));
    processInclude(require('belkin/extend/homePage'));
});

require('core/thirdParty/bootstrap');
require('base/components/spinner');
require('core/thirdParty/lazysizes');
require('core/thirdParty/smartResize');
require('appCore/thirdParty/jquery.waypoints');
require('appCore/thirdParty/matchHeight');
require('core/thirdParty/hoverIntent');
require('belkin/helpers/utilities');

'use strict';

var klarnaMessaging = {
    pdpPrice: null,
    getNestedAttribute: function (nestedObject, pathString) {
        var pathArray = pathString.split('.');
        return pathArray.reduce(function (object, key) {
            return object && object[key] !== 'undefined' ? object[key] : undefined;
        }, nestedObject);
    },
    currencyToNumber: function (input) {
        // note: assumes that if there is a decimal point (either , or .) it will separate two places; £7.6 will not work correctly.
        var output;
        var reDecimalized;
        var cleanedOnce = input.toString().replace(/[^0-9.,-]/g, '');
        var decimalCandidate = cleanedOnce.charAt(cleanedOnce.length - 3);
        var cleanedTwice = cleanedOnce.replace(/[^0-9-]/g, '')
        var negative = cleanedOnce.charAt(0) === '-';
        var numeric = cleanedTwice.replace(/[^0-9]/g, '');
        if (numeric.length === 0) {
            return '0.00';
        }
        if ([',', '.'].includes(decimalCandidate)) {
            reDecimalized = (Number(numeric) / 100);
        } else {
            reDecimalized = (Number(numeric));
        }
        output = negative ? (reDecimalized * -1).toFixed(2) : reDecimalized.toFixed(2);
        return output;
    },
    toMinorUnits: function (input) {
        var output;
        if (Number(input) === 0) {
            return '0';
        }
        var strInput = input.toString();
        var cleaned = input.toString().replace(/[^0-9.-]/g, '');
        var hasTwoPlaces = strInput.charAt(cleaned.length - 3) === '.' ? true : false;
        if (hasTwoPlaces) {
            output = cleaned.replace(/\./g, '');
        } else {
            output = cleaned.replace(/\./g, '') + '00';
        }
        return output;
    },
    klarnifyPrice: function (input) {
        return klarnaMessaging.toMinorUnits(klarnaMessaging.currencyToNumber(input));
    },
    refresh: function () {
        if (window.Klarna && window.Klarna.OnsiteMessaging && window.Klarna.OnsiteMessaging.refresh) {
            window.Klarna.OnsiteMessaging.refresh();
        }
    },
    updateMinicartPlacement: function () {
        window.setTimeout(function () {
            klarnaMessaging.refresh();
            if ($('.klarna-wrapper-minicart').hasClass('preorder-cart')) {
                $('.klarna-wrapper-minicart').css('display', 'none');
            } else {
                $('.klarna-wrapper-minicart').css('display', 'block');
            }
        }, 2000);
    },
    checkForPreOrder: function (items) {
        var isPreOrder = false;
        if (items && items.length) {
            items.forEach(function (item) {
                if (item.availability && item.availability.status && item.availability.status === 'PREORDER') {
                    isPreOrder = true;
                }
            });
            return isPreOrder;
        }
    },
    getSubtotal: function (totals) {
        var subTotal = 0;
        var tax = 0;
        var ship = 0;
        if (totals) {
            if (totals.subTotal) {
                subTotal = klarnaMessaging.currencyToNumber(totals.subTotal);
            }
            if (totals.totalShippingCost) {
                ship = klarnaMessaging.currencyToNumber(totals.totalShippingCost);
            }
            if (totals.totalTax) {
                tax = klarnaMessaging.currencyToNumber(totals.totalTax);
            }
        }
        var calculatedTotal = (Number(subTotal) + Number(tax) + Number(ship)).toFixed(2);
        return calculatedTotal;
    },
    init: function () {
        $('body').on('minicartOpen', function () {
            klarnaMessaging.updateMinicartPlacement();
        });
        $('body').on('cart:update', function (event, data) {
            // data returned from update quantity action has data.items but from remove product line item action it's data.basket.items. Similar difference for totals:
            var theItems;
            var theTotals;
            if (data && data.action) {
                if (data.action === 'Cart-RemoveProductLineItem') {
                    if (data.basket) {
                        if (data.basket.items) {
                            theItems = data.basket.items;
                        }
                        if (data.basket.totals) {
                            theTotals = data.basket.totals;
                        }
                    }
                } else {
                    if (data.items) {
                        theItems = data.items;
                    }
                    if (data.totals) {
                        theTotals = data.totals;
                    }
                }
            }
            // let klarna handle case with missing or inappropriate purchase amount; just give them what we have
            $('.klarna-wrapper-minicart klarna-placement').attr('data-purchase-amount', klarnaMessaging.toMinorUnits(klarnaMessaging.getSubtotal(theTotals)));
            if (theItems && klarnaMessaging.checkForPreOrder(theItems)) {
                $('.klarna-wrapper-minicart').addClass('preorder-cart');
            } else {
                $('.klarna-wrapper-minicart').removeClass('preorder-cart');
            }

            klarnaMessaging.updateMinicartPlacement();
        });
        $('body').on('minicartClose', function () {
            // hide klarna widget so it doesn't do the show-vanish-show when cart is RE-opened
            $('.klarna-wrapper-minicart').css('display', 'none');
        });
        $('body').on('product:updateAvailability', function (event, data) {
            var updatedPrice;
            if (klarnaMessaging.getNestedAttribute(data, 'product.availability.status') === 'IN_STOCK') {
                if (klarnaMessaging.getNestedAttribute(data, 'product.price.sales.value')) {
                    updatedPrice = data.product.price.sales.value;
                }
                if (updatedPrice) {
                    $('.product-detail').find('klarna-placement').attr('data-purchase-amount', klarnaMessaging.klarnifyPrice(updatedPrice));
                    klarnaMessaging.refresh();
                }
                $('.klarna-wrapper-pdp').removeClass('out');
            } else {
                $('.product-detail').find('klarna-placement').attr('data-purchase-amount', '0'); // this will un-display it but...
                klarnaMessaging.refresh();
                $('.klarna-wrapper-pdp').addClass('out'); // let's hide it anyway
            }
        });
    }
};

klarnaMessaging.init();

'use strict';

require('core/thirdParty/slick');
var slickConfig = require('../configs/slickConfigs');


module.exports = function () {
    $('.slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $('.product-slider').slick(slickConfig.cartRecommendation);
};

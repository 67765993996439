'use strict';

/* eslint-disable no-else-return */

var kibo = {
    sendNoPageView: window.monetateSendNoPageView,
    init: function () {
        if (typeof monetate !== 'undefined') {
            var pt = window.monetatePageType || 'other';
            if (pt === 'cart') {
                kibo.sendCartData();
            } else if (pt === 'checkout') {
                kibo.sendCheckoutData();
            } else if (pt === 'purchase') {
                kibo.sendPurchaseData();
            }
            kibo.initAddToCart(pt);
            kibo.initRemoveUpdate(pt);
        }
    },
    getNestedAttribute: function (nestedObject, pathString) {
        var pathArray = pathString.split('.');
        return pathArray.reduce(function (object, key) {
            return object && object[key] !== 'undefined' ? object[key] : undefined;
        }, nestedObject);
    },
    isNonEmptyArray: function (arr) {
        return (arr && arr.length && arr.length > 0);
    },
    stripNonNumeric: function (str) {
        var dotted = str.replace(/,/g, '.');
        return dotted.replace(/[^\d.]/g, '');
    },
    getDataLayerEntry: function (keyExpression) {
        // note: if the dataLayer contains multiple entries with the same key, this will return the last one
        var result;
        var dlr; // dataLayer reversed
        if (kibo.isNonEmptyArray(window.dataLayer)) {
            dlr = window.dataLayer.reverse();
        }
        if (dlr && dlr.length > 0) {
            result = dlr.find(function (entry) {
                return entry.event && keyExpression.test(entry.event);
            });
        }
        return result;
    },
    getPrice: function (item) {
        var price;
        if (kibo.getNestedAttribute(item, 'price.sales.value')) {
            price = item.price.sales.value;
        } else if (kibo.getNestedAttribute(item, 'priceTotal.price')) {
            price = parseFloat(kibo.stripNonNumeric(item.priceTotal.price));
        }
        return price;
    },
    getCurrency: function (item) {
        if (kibo.getNestedAttribute(item, 'price.sales.currency')) {
            return item.price.sales.currency;
        } else if (window.gtmSchema && kibo.getNestedAttribute(window.gtmSchema, 'PDP.data.curency')) {
            return window.gtmSchema.PDP.data.currency;
        }
    },
    getCartData: function () {
        var cartCurrency;
        var cartProductData;
        var cartEntry = kibo.getDataLayerEntry(/ecom\.checkout\.cart/);
        if (kibo.getNestedAttribute(cartEntry, 'ecommerce.checkout.products')) {
            cartProductData = cartEntry.ecommerce.checkout.products;
        }
        if (cartEntry.currency) {
            cartCurrency = cartEntry.currency;
        }
        return {
            cartProductData: cartProductData,
            cartCurrency: cartCurrency
        };
    },
    getCheckoutData: function () {
        var checkoutCurrency;
        var checkoutProductData;
        var checkoutEntry = kibo.getDataLayerEntry(/ecom\.checkout\.(login|shipping|payment|review)/);
        if (kibo.getNestedAttribute(checkoutEntry, 'ecommerce.checkout.products')) {
            checkoutProductData = checkoutEntry.ecommerce.checkout.products;
        }
        if (checkoutEntry.currency) {
            checkoutCurrency = checkoutEntry.currency;
        }
        return {
            checkoutProductData: checkoutProductData,
            checkoutCurrency: checkoutCurrency
        };
    },
    getPurchaseData: function () {
        var purchaseId;
        var purchaseProductData;
        var purchaseCurrency;
        var purchaseEntry = kibo.getDataLayerEntry(/ecom\.checkout\.purchase/);
        if (kibo.getNestedAttribute(purchaseEntry, 'ecommerce.purchase.actionField.id')) {
            purchaseId = purchaseEntry.ecommerce.purchase.actionField.id;
        }
        if (purchaseEntry.currency) {
            purchaseCurrency = purchaseEntry.currency;
        }
        if (kibo.getNestedAttribute(purchaseEntry, 'ecommerce.purchase.products')) {
            purchaseProductData = purchaseEntry.ecommerce.purchase.products;
        }
        return {
            purchaseId: purchaseId,
            purchaseCurrency: purchaseCurrency,
            purchaseProductData: purchaseProductData
        };
    },
    getPageType: function () {
        var eventEntries;
        var pageType = '';

        var homeRe = /^\/([a-z]{2}\/)*$/;
        // if it's an ecommerce page with a dataLayer entry, get the page type from that
        if (window.dataLayer) {
            eventEntries = window.dataLayer.filter(function (entry) {
                return entry.event;
            });
            if (kibo.isNonEmptyArray(eventEntries)) {
                eventEntries.forEach(function (entry) {
                    if (entry.event === 'ecom.product.list') {
                        if (location.search.length > 0) {
                            pageType = 'search';
                        } else {
                            pageType = 'index';
                        }
                    } else if (entry.event === 'ecom.product.detail') {
                        pageType = 'product';
                    } else if (entry.event === 'ecom.checkout.purchase') {
                        pageType = 'purchase';
                    } else if (entry.event === 'ecom.checkout.cart') {
                        pageType = 'cart';
                    } else if (entry.event.indexOf('ecom.checkout.') === 0) {
                        pageType = 'checkout';
                    }
                });
            }
        }
        // otherwise, check to see if it is a home page
        if (pageType === '' && location.pathname.match(homeRe)) {
            pageType = 'main';
        }
        // otherwise check for support pages
        if (pageType === '' && location.pathname.indexOf('support') !== -1) {
            pageType = 'support';
        }
        // otherwise check for resource center pages
        if (pageType === '' && location.pathname.indexOf('product-resources') !== -1) {
            pageType = 'resource';
        }
        // otherwise we punt
        if (pageType === '') {
            pageType = 'other';
        }
        return pageType;
    },

    sendCartData: function () {
        var kiboCartData;
        var cd = kibo.getCartData();
        if (kibo.isNonEmptyArray(cd.cartProductData)) {
            kiboCartData = cd.cartProductData.map(function (prod) {
                return {
                    productId: prod.id,
                    quantity: prod.quantity,
                    unitPrice: prod.price.toFixed(2),
                    currency: cd.cartCurrency
                };
            });
        }
        if (window.monetateQ && kibo.isNonEmptyArray(kiboCartData)) {
            window.monetateQ.push(['addCartRows', kiboCartData]);
            window.monetateQ.push(['trackData', { nonPageView: true }]);
        }
    },
    sendCheckoutData: function () {
        var kiboCheckoutData;
        var cd = kibo.getCheckoutData();
        if (kibo.isNonEmptyArray(cd.checkoutProductData)) {
            kiboCheckoutData = cd.checkoutProductData.map(function (prod) {
                return {
                    productId: prod.id,
                    quantity: prod.quantity,
                    unitPrice: prod.price,
                    currency: cd.checkoutCurrency
                };
            });
        }
        if (window.monetateQ && kibo.isNonEmptyArray(kiboCheckoutData)) {
            window.monetateQ.push(['addCartRows', kiboCheckoutData]);
            window.monetateQ.push(['trackData', { nonPageView: true }]);
        }
    },
    sendPurchaseData: function () {
        var kiboPurchaseData;
        var pd = kibo.getPurchaseData();
        if (kibo.isNonEmptyArray(pd.purchaseProductData)) {
            kiboPurchaseData = pd.purchaseProductData.map(function (prod) {
                return {
                    purchaseId: pd.purchaseId,
                    productId: prod.id,
                    quantity: prod.quantity,
                    unitPrice: prod.price,
                    currency: pd.purchaseCurrency
                };
            });
        }
        if (window.monetateQ && kibo.isNonEmptyArray(kiboPurchaseData)) {
            window.monetateQ.push(['addPurchaseRows', kiboPurchaseData]);
            window.monetateQ.push(['trackData', { nonPageView: true }]);
        }
    },
    initAddToCart: function (pt) {
        if (window.jQuery) {
            $('body').on('product:afterAddToCart', function (event, data) {
                var cartItems;
                var cartProducts;
                if (data) {
                    cartItems = kibo.getNestedAttribute(data, 'cart.items');
                }
                if (kibo.isNonEmptyArray(cartItems)) {
                    cartProducts = cartItems.map(function (item) {
                        return {
                            productId: item.analyticsId,
                            quantity: item.quantity,
                            unitPrice: kibo.getPrice(item),
                            currency: kibo.getCurrency(item)
                        };
                    });
                }
                if (kibo.isNonEmptyArray(cartProducts)) {
                    window.monetateQ = window.monetateQ || [];
                    window.monetateQ.push(['setPageType', pt]);
                    window.monetateQ.push(['addCartRows', cartProducts]);
                    window.monetateQ.push(['trackData', { nonPageView: true }]);
                }
            });
        }
    },
    initRemoveUpdate: function (pt) {
        $('body').on('cart:update', function (event, data) {
            var remainingCartItems;
            var remainingCartProducts;
            if (data.action === 'Cart-RemoveProductLineItem'
                && kibo.getNestedAttribute(data, 'basket.items')
                && kibo.isNonEmptyArray(data.basket.items)) {
                remainingCartItems = data.basket.items;
            } else if (data.action === 'Cart-UpdateQuantity' && kibo.isNonEmptyArray(data.items)) {
                remainingCartItems = data.items;
            }
            if (kibo.isNonEmptyArray(remainingCartItems)) {
                remainingCartProducts = remainingCartItems.map(function (item) {
                    return {
                        productId: item.analyticsId,
                        quantity: item.quantity,
                        unitPrice: kibo.getPrice(item),
                        currency: kibo.getCurrency(item)
                    };
                });
            }
            if (kibo.isNonEmptyArray(remainingCartProducts)) {
                window.monetateQ = window.monetateQ || [];
                window.monetateQ.push(['setPageType', pt]);
                window.monetateQ.push(['addCartRows', remainingCartProducts]);
                window.monetateQ.push(['trackData', { nonPageView: true }]);
            } else {
                window.monetateQ = window.monetateQ || [];
                window.monetateQ.push(['setPageType', pt]);
                window.monetateQ.push(['addCartRows', []]);
                window.monetateQ.push(['trackData', { nonPageView: true }]);
            }
        });
    },
    initShowMore: function () {
        if (window.jQuery) {

        }
    }
};

window.addEventListener('load', function () {
    kibo.init();
});
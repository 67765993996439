'use strict';

var videojsPlugin = require('video.js').default;
var videojsYoutubePlugin = require('videojs-youtube'); // eslint-disable-line

var videoDefaultOptions = require('../configs/videoDefault.json');

/**
 * Getting video source object
 * @param {String} url - video URL
 * @returns {Object} configuration object
 */
function getVideoSource(url) {
    var config = {};

    if (url) {
        config.sources = [
            {
                src: url,
                type: "video/youtube"
            }
        ];
    }

    return config;
}

/**
 * Getting poster url string
 * @param {String} videoId - id of video
 * @returns {String} - url string
 */
function getPosterUrl(videoId) {
    return 'https://img.youtube.com/vi/' + videoId + '/maxresdefault.jpg'
}

/**
 * @function
 * @description initEvents
 */
 function initEvents() {
    var $this = this;

    $(document).on('click', '.js-video-create-btn', function () {
        var $this = $(this);
        var $videoBlock = $('.js-video-block');
        var $playerSubHead = $('.product-player__sub-heading');
        var videoUrl = $this.data('url');
        var videoId = $this.data('video-id');
        var videoTitle = $this.data('vtitle');
        var posterUrl = getPosterUrl(videoId);
        var videoPlayer = $('.js-video')[0].player;

        if (!videoPlayer) {
            $('.js-video').data('video', videoUrl).attr('poster', posterUrl);
            $('.js-product-player__video-frame').css('background-image', 'url(' + posterUrl + ')');
        } else {
            videoPlayer.src(getVideoSource(videoUrl).sources);
            $('.vjs-poster').css('background-image', 'url(' + posterUrl + ')');
            $videoBlock.removeClass('is-played');
            $videoBlock.addClass('is-loaded-list-video');
        }
        $playerSubHead.text(videoTitle);
        $('.js-video-create-btn').removeClass('m-active');
        $this.addClass('m-active');
    });

    $(document).on('click', '.js-videoplay-btn', function () {
        var $playBtn = $(this);
        var $videoBlock = $playBtn.closest('.js-video-block');
        var $videoElSelector = $videoBlock.find('.js-video');

        $this.create($videoElSelector[0]);

        var videoElement = $videoElSelector[0];

        if (videoElement && videoElement.player) {
            if (videoElement.player.paused()) {
                var playDelay = 500;

                var $currentVideoEl = $videoBlock.find('.js-video');
                var videoInitInterval;

                videoInitInterval = setInterval(function() {
                    if ($currentVideoEl.is('.m-inited')) {
                        $videoBlock.removeClass('is-loaded-list-video');
                        $currentVideoEl[0].player.play();
                        $videoBlock.addClass('is-played');

                        clearInterval(videoInitInterval);
                    }

                }, playDelay, $currentVideoEl);
            } else {
                videoElement.player.pause();
                $videoBlock.removeClass('is-played');
            }
            window.setTimeout(function () {
                $(videoElement).removeClass('vjs-controls-disabled');
            }, 500);
        }
    });

    $(document).on('initVideos', function (event, data) {
        $this.init({
            container: data.container
        });
    });
}

/**
 * Event handler for video
 * @param {Object} videoPlayer - Video player
 */
function initEventHandler(videoPlayer) {
    videoPlayer.on('play', function () {
        if ($('.js-video-block').is('.is-loaded-list-video')) {
            videoPlayer.pause();
        }
    });

    videoPlayer.on('ended', function () {
        videoPlayer.hasStarted(false);
    });
}

module.exports = {
    init: function () {
        var $this = this;
        var $videoBlocks = $('.js-video:not(.m-lazy-loaded)');

        if ($videoBlocks.length > 0) {
            $videoBlocks.each(function () {
                $this.create(this);
            });
        }
    },

    initEvents: initEvents,

    create: function (selector) {
        if (!selector) {
            return;
        }

        var $video = $(selector);
        var videoSources = getVideoSource($video.data('video'));
        var videoOptions = $.extend({}, videoDefaultOptions, videoSources);

        videojsPlugin($video.get(0), videoOptions, function () {
            var videoPlayer = this;
            var videoElement = videoPlayer.contentEl();
            var $videoElement = $(videoElement);

            $videoElement.addClass('js-video-el m-inited');

            $videoElement.closest('.slick-slider').on('beforeChange', function () {
                var videoElement = $videoElement[0];
                if (videoElement && videoElement.player) {
                    videoPlayer.pause();
                }
            });

            videoElement.player = videoPlayer;

            initEventHandler(videoPlayer);
        });
    }
};

'use strict';

var actionValue = '';

/**
 * Method to open locale modal
 * @param {string} $target - target modal
 * @param {string} action - user action
 */
function toggleLocaleModal($target, action) {
    $($target).modal(action);
    if (action === 'hide') {
        setTimeout(function () {
            $($target).remove();
        }, 2000);
    }
}

/**
 * User Action for Locale Modal
 * @param {string} action - user selected action
 */
function userAction(action) {
    actionValue = action;
    var $localeDetection = $('.js-locale-detection-modal');
    var urlContent = $localeDetection.find('.js-buttons').first().data('url-action');
    var separator = window.location.href.indexOf('?') === -1 ? '?' : '&';

    $.ajax({
        url: urlContent,
        type: 'post',
        dataType: 'json',
        data: { action: action, savePreference: $('.js-geo-save-preferences').is(':checked') },
        success: function (response) {
            if (action === 'accepted' && response.redirectURL) {
                if (document.querySelectorAll('.product-detail').length > 0) {
                    window.location = response.redirectURL + separator + 'isPdpRedirect=true';
                } else {
                    window.location = response.redirectURL;
                }
            }
        },
        error: function () {
            toggleLocaleModal($localeDetection, 'hide');
        }
    });

    // close modal
    toggleLocaleModal($localeDetection, 'hide');
}

module.exports = {
    initLocale: function () {
        // check to see if the window is being rendered inside of the Page Designer Editor and that the browser is not a search crawler
        if (window.location === window.parent.location && !window.isCrawler) {
            // Execute only on homepage
            var $redirectContainer = $('.js-redirect-container');
            if ($redirectContainer.length) {
                window.location = $redirectContainer.first().data('url');
            } else {
                var $localeDetection = $('.js-locale-detection-modal');
                if ($localeDetection.length) {
                    toggleLocaleModal($localeDetection, 'show');
                }
            }
        }
    },

    localeAccept: function () {
        $('body').on('click touched', '.locale-accept', function () {
            userAction('accepted');
        });
    },

    localeReject: function () {
        $('body').on('click touched', '.locale-reject', function () {
            userAction('rejected');
        });
    }
};

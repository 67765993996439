'use strict';
// console.log('%crunning toolTip.js in app_belkin...', 'color: green');
module.exports = {
    initForBlocks: function () {
        var thisObj = this;

        $('.tooltip-block').each(function () {
            var tooltipContent = $(this).find('.tooltip').text();
            if ($(this).find('.tooltip').find('.content-asset').length) {
                tooltipContent = $(this).find('.tooltip').find('.content-asset').text();
            }

            $(this).attr('title', tooltipContent);

            thisObj.init($(this));
        });
    },
    init: function ($elem) {
        var $tooltip = $('[data-toggle="tooltip"]');

        if ($elem) {
            $tooltip = $elem;
        }

        $tooltip.tooltip({
            container: 'body',
            html: true
        });
    }
}

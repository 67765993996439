'use strict';

const { trim } = require('jquery');

let formControls = "input[type='date']:not(.m-disable-floating), input[type='email'], input[type='password'], input[type='search']"
        + ", input[type='tel'], input[type='text']:not(.m-disable-floating), input[type='url'], select:not(.m-disable-floating), textarea, input[type='number'";

/**
 * Initialize Float Labels
 */
function initFloatLabels() {
    let $formControls = $(formControls);

    $('form').addClass('floating'); // add glabl utility class to forms for manipluating specific elemtents

    $formControls.each(function () {
        let $currEl = $(this);
        let $label = $(this).parents('.form-group').find('label');
        let required = $(this).parents('.required').length > 0;
        let labelText;

        if ($currEl.hasClass('no-float')) {
            return;
        }

        if (required) {
            labelText = '* ' + $label.text().trim();
        } else {
            labelText = $label.text().trim();
        }

        $(this).parents('.form-group').addClass('floating-label');

        // move label to follow input
        $label.insertAfter($(this));

        // if no palceholder is set, use label text
        if (!$(this).attr('placeholder')) {
            $(this).attr('placeholder', labelText);
        }

        // detect whether select has a empty "placeholder" value as first option, or an actual value
        if ($currEl.is('select')) {
            var $firstOption = $currEl.find('option:first-child');

            $currEl.parents('.form-group').addClass('floating-select');

            if ($.trim($firstOption.html()).length) {
                $currEl.parents('.form-group').addClass('default-set');
            }
        }
    });

    // select float label functionality
    $('.form-control').on('focus blur change input', function (e) {
        var $currEl = $(this);

        if ($currEl.is('select')) {
            var $firstOption = $currEl.find('option:first-child');
            var labelText = $currEl.siblings('label').text().trim();

            // if first option is empty, then assign the labvel text as "placeholder" option
            if (!$.trim($firstOption.html()).length) {
                $firstOption.text(labelText).attr('label', labelText);
            }

            // if the first value is a "placeholder" apply float label style, if it has a default value apply "selected" style
            if ($firstOption.is(':selected')) { // first option is selected
                if (!$currEl.parents('.default-set').length) { // first option is a placeholder and not a "default"
                    $currEl.parent().removeClass('focused');
                } else { // first option is a "default"
                    $currEl.parent().addClass('focused');
                }
            } else { // first option is not selected
                $currEl.parents('.form-group').toggleClass('focused', ((e.type === 'focus' || this.value.length > 0) && (!$firstOption.is(':selected'))));
            }
        } else { // not a select element
            $currEl.parents('.form-group').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
        }
    }).trigger('blur');
}
/**
 * Initialize Hidden Labels
 */
function initHiddenLabels() {
    let $formControls = $(formControls);

    $('form').addClass('floating'); // add global utility class to forms for manipulating specific elements

    $formControls.each(function () {
        let $currEl = $(this);
        let $label = $(this).parents('.form-group').find('label');
        let required = $(this).parents('.required').length > 0;
        let labelText;
        // keep labels for prod-reg page
        if ($('#productRegisterForm').length === 0) {
            $label.hide();
        }

        if (required) {
            labelText = '* ' + $label.text().trim();
        } else {
            labelText = $label.text().trim();
        }
        // keep labels for prod-reg page
        if ($('#productRegisterForm').length === 0) {
            $(this).parents('.form-group').addClass('hidden-label');
        }

        // if no placeholder is set, use label text
        if (!$(this).attr('placeholder')) {
            $(this).attr('placeholder', labelText);
        }

        // detect whether select has a empty "placeholder" value as first option, or an actual value
        if ($currEl.is('select')) {
            var $firstOption = $currEl.find('option:first-child');

            $currEl.parents('.form-group').addClass('hidden-select-label');

            if ($.trim($firstOption.html()).length) {
                $currEl.parents('.form-group').addClass('default-set');
            } else {
                $firstOption.text(labelText);
            }
        }
    });
}

module.exports = function () {
    let features = JSON.parse(document.querySelector('body').dataset.features);

    if (features) {
        switch (features.formLabelType) {
            default:
            case 'normalLabels':
                break;
            case 'floatLabels':
                initFloatLabels();
                break;
            case 'hiddenLabels':
                initHiddenLabels();
        }
    }
};

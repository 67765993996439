'use strict';

/* eslint-disable require-jsdoc */

function currencyToNumber(input) {
    var numeric;
    var output;
    var cleaned = input.toString().replace(/[^0-9.,-]/g, '');
    var decimalCandidate = cleaned.charAt(cleaned.length - 3);
    numeric = Number(cleaned.replace(/[.,]/g, ''));
    if ([',', '.'].includes(decimalCandidate)) {
        output = (numeric / 100).toFixed(2);
    } else {
        output = numeric.toFixed(2);
    }
    return output;
}

function toMinorUnits(number) {
    return parseInt(number * 100, 10).toFixed(0);
}

window.currencyToNumber = currencyToNumber;
window.toMinorUnits = toMinorUnits;